html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-snap-type: mandatory;
}

sombra *,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  width: 100%;
  margin: 0;
  position: absolute;
}

p {
  font-family: "Poppins", sans-serif;
  color: #000000;
  overflow-y: hidden;
  text-decoration: none;
}

.left {
  margin-left: 0;
}

a {
  text-decoration: none;
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

h1,
h2,
h3 {
  margin: 0 0 5rem 0;
}

h1 {
  font-size: 5rem;
  color: #BEA374;
  text-transform: uppercase;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 3rem;
}

.aling-rigth {
  justify-self: end;
}
@media (min-width: 40rem) {
  .aling-rigth {
    justify-self: normal;
  }
}

.aling-left {
  justify-self: start;
}

.icon-wsp {
  position: fixed;
  width: 7rem;
  height: 7rem;
  bottom: 5rem;
  right: 2rem;
  color: white;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  z-index: 3;
}
@media (min-width: 40rem) {
  .icon-wsp {
    right: 5%;
  }
}

.espacio {
  height: 4rem;
  margin: 0;
  padding: 0;
}

picture {
  height: 0px;
  font-size: 0px;
}

.contenedor {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  background-color: #03101e;
  background-color: #03101e;
}
@media (min-width: 40rem) {
  .contenedor {
    width: 80%;
    height: 100%;
  }
}
.contenedor_header {
  background-color: #03101e;
  width: 100%;
  height: 5rem;
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
}

.contendedor-perfil, .contendedor-titulo {
  background-color: #FFFFFF;
}

.contenedor-servicios {
  width: 100%;
  margin: 5rem auto;
  background-color: #FFFFFF;
}

.contenedor-contacto {
  background-color: transparent;
  display: block;
  width: 100%;
}
@media (min-width: 40rem) {
  .contenedor-contacto {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.contenedor-formulario {
  width: 80%;
  margin: auto;
}
.contenedor-formulario .fieldset-crud {
  margin-bottom: 5rem;
  box-shadow: none;
  margin: 0;
}

.contenedor-footer {
  width: 100%;
}

.boton {
  margin: 0 auto 2rem;
  width: 150px;
  text-align: center;
  vertical-align: middle;
  background-color: #FFFFFF;
  border-radius: 10px;
}
.boton:hover {
  cursor: pointer;
  background-color: #BEA374;
  border-radius: 1rem;
  color: #FFFFFF;
  font-weight: bold;
}
@media (min-width: 40rem) {
  .boton {
    width: 250px;
  }
}

.btn-green {
  background-color: #05F139;
  min-width: 15%;
}
.btn-green a {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto;
}
@media (min-width: 40rem) {
  .btn-green a {
    font-size: 20px;
  }
}

.btn-enviar {
  background-color: #05F139;
  margin-top: 1rem;
  width: 10rem;
}

.btn-admin {
  padding: 0.2rem 1rem;
  margin: 5rem;
  font-size: 12px;
}

.titulo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 10rem 0 5rem;
}
.titulo .line-blue {
  width: 70%;
  height: 0px;
  border: solid 1px #0080FF;
  margin: auto;
}
.titulo p {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 2.4px;
  color: #03101e;
  margin: auto;
  padding-left: 1rem;
  border-left: solid 2px #0080FF;
}
@media (min-width: 40rem) {
  .titulo p {
    font-size: 30px;
  }
}
@media (min-width: 70rem) {
  .titulo p {
    font-size: 40px;
  }
}

.fondo-imagen {
  position: fixed;
  top: 20rem;
  left: 2.85rem;
  height: 20rem;
  z-index: -5;
}
.fondo-imagen picture img {
  width: 100%;
  height: 20rem;
}

.formulario-contacto {
  width: 80%;
  margin: 0 10%;
  max-width: 50rem;
}

.formulario fieldset {
  background-color: #e2e2e2;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px #8f8f8f;
}
.formulario p {
  font-size: 1rem;
  color: #000000;
  margin: 2rem 0 0 0;
}
.formulario legend {
  font-size: 2rem;
  color: #000000;
  font-weight: 600;
}
.formulario label {
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}
.formulario input:not([type=submit]),
.formulario textarea,
.formulario select {
  padding: 1rem;
  display: block;
  width: 90%;
  background-color: #FFFFFF;
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.formulario textarea {
  height: 10rem;
}
.formulario .forma-contacto {
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formulario input[type=radio] {
  margin: 0;
  width: auto;
}

.footer-redsocial {
  display: flex;
  gap: 2rem;
  margin: auto;
  grid-column: 1/3;
}
@media (min-width: 40rem) {
  .footer-redsocial {
    gap: 4rem;
  }
}
.footer-redsocial a svg {
  width: 2rem;
  height: 2rem;
}
@media (min-width: 40rem) {
  .footer-redsocial a svg {
    width: 3rem;
    height: 3rem;
  }
}

.crud-titulo {
  text-align: center;
  margin: auto;
  font-size: 25px;
  font-weight: 400;
  padding-top: 2rem;
  margin-bottom: 5rem;
}

.enviar {
  margin: auto;
}
.enviar input {
  width: 20%;
  margin: 1rem auto;
}

.alerta {
  padding: 1rem 0 1rem 2rem;
}

.error {
  color: #f53333;
}

.exito {
  background-color: #05F139;
  text-align: center;
  border-radius: 10px;
}

.barra {
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  background-color: #03101e;
}
@media (min-width: 40rem) {
  .barra {
    width: 80%;
    justify-content: end;
    margin: 0 auto;
  }
}
.logo {
  align-content: initial;
  margin: 1rem auto 0;
  width: 80px;
  height: 70px;
}
.logo img {
  width: 80px;
  height: 70px;
}
@media (min-width: 40rem) {
  .logo {
    display: none;
  }
}

.navegacion-principal {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  border-radius: 10px;
}
@media (min-width: 40rem) {
  .navegacion-principal {
    display: block;
    margin: auto;
  }
}

.nav-bar {
  display: block;
  margin: 1rem auto;
  text-align: center;
}
@media (min-width: 40rem) {
  .nav-bar {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 1rem;
  }
}

.nav-tex {
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
  height: 75%;
  display: flex;
  flex-direction: column;
  row-gap: 1.1rem;
  border-radius: 10px;
  background-color: #03101e;
  margin-right: 1rem;
}
@media (min-width: 20rem) {
  .nav-tex {
    display: none;
  }
}
@media (min-width: 40rem) {
  .nav-tex {
    display: grid;
    margin-right: 0;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    font-size: 14px;
  }
}
@media (min-width: 70rem) {
  .nav-tex {
    gap: 2rem;
    font-size: 16px;
  }
}
.nav-tex a {
  color: #FFFFFF;
  padding: 0 0.5rem;
  text-align: center;
  margin: auto;
}
.nav-tex a:hover {
  cursor: pointer;
  background-color: #BEA374;
  border-radius: 1rem;
  color: #000000;
  font-weight: normal;
}

.mobile-menu {
  cursor: pointer;
}
@media (min-width: 40rem) {
  .mobile-menu {
    display: none;
  }
}

.mostrar {
  display: flex;
}

.inicio-imagen {
  position: relative;
  height: 50rem;
}
@media (min-width: 40rem) {
  .inicio-imagen {
    height: 57rem;
  }
}
@media (min-width: 70rem) {
  .inicio-imagen {
    height: 65rem;
  }
}
.inicio-imagen picture {
  height: 0;
}
.inicio-imagen picture #inicio-imagen {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}
@media (min-width: 40rem) {
  .inicio-imagen picture #inicio-imagen {
    opacity: 0.8;
  }
}

.contenedor-inicio {
  width: 80%;
  min-width: 34rem;
  margin: auto;
  display: grid;
  grid-template-rows: repeat(4, auto);
  gap: 2rem;
  padding-top: 5rem;
  z-index: 1;
  position: absolute;
  z-index: 4;
  top: 10%;
}
@media (min-width: 40rem) {
  .contenedor-inicio {
    width: 50%;
    top: 5%;
    margin-left: 2rem;
  }
}
@media (min-width: 70rem) {
  .contenedor-inicio {
    width: 50%;
    top: 0%;
    margin-left: 2rem;
  }
}

#inicio-logo {
  display: none;
}
@media (min-width: 40rem) {
  #inicio-logo {
    display: block;
  }
  #inicio-logo picture img {
    width: 1rem;
    height: 15rem;
  }
}
@media (min-width: 40rem) and (min-width: 70rem) {
  #inicio-logo picture img {
    width: 15rem;
    height: 20rem;
  }
}

.inicio-h1 {
  width: 100%;
  margin-top: 0;
  text-align: center;
}
@media (min-width: 40rem) {
  .inicio-h1 {
    margin-top: 5%;
    text-align: center;
  }
}
.inicio-h1 h1 {
  font-size: 30px;
  margin: 0;
}
@media (min-width: 40rem) {
  .inicio-h1 h1 {
    font-size: 40px;
  }
}
@media (min-width: 70rem) {
  .inicio-h1 h1 {
    font-size: 50px;
  }
}
@media (min-width: 70rem) {
  .inicio-h1 {
    margin-top: 10%;
    text-align: center;
  }
}

.inicio-h2 {
  margin-top: 5%;
  text-align: center;
}
@media (min-width: 70rem) {
  .inicio-h2 {
    margin-top: 10%;
  }
}
.inicio-h2 p {
  color: var(--color-white, #FFF);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.8px;
}
@media (min-width: 70rem) {
  .inicio-h2 p {
    font-size: 22px;
    line-height: 28px;
  }
}
.inicio-h2 span {
  color: var(--color-white, #FFF);
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.9px;
}
@media (min-width: 70rem) {
  .inicio-h2 span {
    line-height: 40px;
    font-size: 25px;
  }
}

@media (min-width: 40rem) {
  .perfil {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
.perfil .perfil-imagen picture img {
  margin: 0 25%;
  width: 50%;
  height: 30%;
}
@media (min-width: 40rem) {
  .perfil .perfil-imagen picture img {
    margin: 0 15%;
    width: 80%;
    height: 100%;
    max-height: 50rem;
  }
}
.perfil .perfil-text {
  margin-top: 2rem;
}
@media (min-width: 40rem) {
  .perfil .perfil-text {
    margin-top: 0rem;
  }
}
.perfil .perfil-text p {
  margin: 0rem auto;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
}
@media (min-width: 40rem) {
  .perfil .perfil-text p {
    font-size: 16px;
  }
}
@media (min-width: 70rem) {
  .perfil .perfil-text p {
    font-size: 18px;
  }
}

.servicios-imagen {
  position: relative;
}
.servicios-imagen img {
  width: 100%;
  height: 35rem;
}

.servicios-contenedor-tarjetas {
  display: grid;
  width: 80%;
  padding: 5% 0;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media (min-width: 40rem) {
  .servicios-contenedor-tarjetas {
    grid-template-columns: repeat(3, 1fr);
    padding: 25% 0;
  }
}
@media (min-width: 40rem) {
  .servicios-contenedor-tarjetas {
    grid-template-columns: repeat(3, 1fr);
    padding: 5% 0;
  }
}

.servicio-tarjeta {
  display: grid;
  width: 100%;
  max-width: 20rem;
  background-color: #FFFFFF;
  border: solid rgb(41, 41, 41) 1px;
  border-radius: 5px;
}
@media (min-width: 40rem) {
  .servicio-tarjeta {
    max-width: 40rem;
  }
}
.servicio-tarjeta .servicio-icon {
  margin-bottom: 0;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  text-align: left;
}
.servicio-tarjeta .servicio-icon img {
  width: 3rem;
  height: 3rem;
}
.servicio-tarjeta p {
  text-align: left;
  color: #000000;
  font-size: 10px;
  margin: 0.5rem 0 0.5rem 0.5rem;
}
@media (min-width: 40rem) {
  .servicio-tarjeta p {
    font-size: 16px;
  }
}
@media (min-width: 70rem) {
  .servicio-tarjeta p {
    font-size: 20px;
  }
}
.servicio-tarjeta:hover {
  cursor: pointer;
  background-color: #BEA374;
  border-radius: 1rem;
  color: #FFFFFF;
  font-weight: normal;
}

.servicios-hoja-contenedor {
  background-color: #FFFFFF;
  padding-top: 2rem;
}
@media (min-width: 40rem) {
  .servicios-hoja-contenedor {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 70rem) {
  .servicios-hoja-contenedor {
    grid-template-columns: repeat(3, 1fr);
  }
}

.servicio-hoja-tarjeta {
  width: 25rem;
  margin: 5rem auto;
  box-shadow: 5px 5px 5px 0px #8f8f8f;
  border: solid #03101e 1px;
}
@media (min-width: 40rem) {
  .servicio-hoja-tarjeta {
    width: 25rem;
  }
}
@media (min-width: 70rem) {
  .servicio-hoja-tarjeta {
    width: 30rem;
  }
}
.servicio-hoja-tarjeta .servicio-hoja-tarjeta-imagen img {
  width: 100%;
  height: 20rem;
}
@media (min-width: 40rem) {
  .servicio-hoja-tarjeta .servicio-hoja-tarjeta-imagen img {
    width: 100%;
    height: 20rem;
  }
}
@media (min-width: 70rem) {
  .servicio-hoja-tarjeta .servicio-hoja-tarjeta-imagen img {
    width: 100%;
    height: 30rem;
  }
}
.servicio-hoja-tarjeta .servicio-hoja-tarjeta-texto .servicio-hoja-titulo p {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0.5rem;
}
@media (min-width: 40rem) {
  .servicio-hoja-tarjeta .servicio-hoja-tarjeta-texto .servicio-hoja-titulo p {
    font-size: 18px;
  }
}
.servicio-hoja-tarjeta .servicio-hoja-tarjeta-texto .servicio-hoja-texto {
  font-size: 10px;
  margin: 0 0.5rem;
  text-align: justify;
}
@media (min-width: 40rem) {
  .servicio-hoja-tarjeta .servicio-hoja-tarjeta-texto .servicio-hoja-texto {
    margin: 0 1rem;
    font-size: 14px;
  }
}

.linkexterno {
  color: #BEA374;
  font-weight: 900;
}

.linkexternonegro {
  font-weight: 900;
}

.contacto-imagen {
  width: 100%;
  height: 55rem;
  background-image: linear-gradient(rgba(3, 16, 30, 0.5019607843), rgba(3, 16, 30, 0.5019607843)), url("../../../build/img/contacto-abogado.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 3rem;
}

.contacto-text {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 2rem 7% 10rem 17%;
}
.contacto-text .text1 {
  padding-left: 1rem;
  border-left: solid 2px #0080FF;
}
.contacto-text .text1 p {
  color: #BEA374;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}
.contacto-text .text2 p,
.contacto-text .text3 p {
  color: #FFFFFF;
}
.contacto-text .text2 p {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 5rem;
}
@media (min-width: 40rem) {
  .contacto-text .text2 p {
    line-height: 1.5;
  }
}
.contacto-text .text3 p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 2rem 0;
}
@media (min-width: 40rem) {
  .contacto-text .text3 p {
    font-size: 14px;
  }
}

.footer-imagen {
  position: relative;
}
.footer-imagen #footer-imagen {
  width: 100%;
  height: 25rem;
  filter: brightness(0.1);
}

.footer-informacion-general {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 80%;
  margin: 5rem 10%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
}
@media (min-width: 40rem) {
  .footer-informacion-general {
    margin: 3rem 10%;
  }
}

.footer-informacion {
  display: block;
  padding: auto;
}

.footer-linea-texto {
  display: flex;
}
.footer-linea-texto .logopeq {
  margin: auto 0px;
}
.footer-linea-texto p {
  color: #FFFFFF;
  margin-left: 1rem;
  font-size: 10px;
}

.footer-logo {
  margin: auto;
}
.footer-logo #footer-logo {
  width: 8rem;
  height: 7rem;
  margin: auto;
  filter: brightness(1);
}

.copyright {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  height: 3rem;
  background-color: #e2e2e2;
}
.copyright p {
  margin: auto;
  font-size: 10px;
  text-align: center;
}
.copyright .copyright-text {
  margin: auto;
}
.copyright .petisoft a {
  color: red;
}