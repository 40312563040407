@use 'base/variables' as v;
@use 'base/mixins' as m;


.footer-redsocial {
    display: flex;
    gap: 2rem;
    margin: auto;
    grid-column: 1 / 3;
    ;

    @include m.tablet {
        gap: 4rem;
    }

    a {

        svg {

            width: 2rem;
            height: 2rem;


            @include m.tablet { 
              
            width: 3rem;
            height: 3rem;
            }
        }

        
    }
}