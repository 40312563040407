@use '../base/variables' as v;
@use '../base/mixins' as m;


.contacto-imagen {
    width:100%;
    height: 55rem;
    background-image: linear-gradient(#03101e80, #03101e80), url("../../../build/img/contacto-abogado.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin-bottom: 3rem ;
    
    

}

.contacto-text {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 2rem 7% 10rem 17%;
    // border: solid rgb(11, 238, 22) 1px;

    .text1 {
        padding-left: 1rem;
        border-left: solid 2px v.$blue-color3;

        p {
            color: v.$claro-color;
            font-weight: bold;
            font-size: 24px;
            margin: 0;

        }

    }

    .text2,
    .text3 {
        p {
            color: v.$white_color;



        }

    }

    .text2 {

        p {

            font-size: 2rem;
            font-weight: 600;
            line-height: 1;
            
            margin: 0;
            margin-bottom: 5rem;

            @include m.tablet {
                line-height: 1.5;
                
            }

            
        }

    }

    .text3 {
        p {

            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            margin: 2rem 0;

            @include m.tablet { 
                font-size: 14px;
            }
            
        }
    }
}