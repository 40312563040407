@use 'base/variables' as v;
@use 'base/mixins' as m;

.contenedor {

    width: 90%;
    height: 100%;
    margin: 0 auto;
    
    background-color: v.$blue-color;
    // border: solid rgb(11, 238, 22) 1px;


    @include m.tablet {
        width: 80%;
        height: 100%;
        // border: solid rgb(11, 238, 22) 1px;
    }


    @include m.desktop {

        
       
        // border: solid rgb(238, 10, 10) 1px;
    }

    background-color: v.$blue-color;

}

.contenedor_header {
    background-color: v.$blue-color;
    width: 100%;
    height: 5rem;
    position: fixed;
    z-index: 10;
    right: 0;
    left: 0;

    // border: solid rgb(238, 10, 10) 1px;
}


.contendedor-perfil, .contendedor-titulo{
    background-color: v.$white_color;
}

.contenedor-servicios {
    width: 100%;
    margin: 5rem auto;
    background-color: v.$white_color;
    
   
}


.contenedor-contacto {
    background-color: transparent;
    display: block;
    width: 100%;


    @include m.tablet{ 
          display: grid;
          grid-template-columns: repeat(2, 1fr);
    }

    
}

.contenedor-formulario {
    width: 80%;
    margin: auto;

    
    .fieldset-crud {
        margin-bottom: 5rem;
        box-shadow: none;
        margin: 0;
       
    }

}


.contenedor-footer{
    width: 100%;
    
    // border: solid rgb(238, 10, 10) 1px;

}