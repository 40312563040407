@use 'base/variables' as v;
@use 'base/mixins' as m;


.crud-titulo {
    text-align: center;
    margin: auto;
    font-size: 25px;
    font-weight: 400;
    padding-top: 2rem;
    margin-bottom: 5rem;
}


.enviar {
    margin: auto;
    input {
        width: 20%;
        margin: 1rem auto;
    }
}


.alerta {
    padding: 1rem 0 1rem 2rem
}


.error {
    color: v.$red;
}
.exito {
    background-color: v.$green-color;
    text-align: center;
    border-radius: 10px;
    
}