@use 'base/variables' as v;
@use 'base/mixins' as m;


.formulario-contacto{
    width: 80%;
    margin: 0 10%;
    max-width: 50rem;
    // background-color: v.$gris-claro;
}


.formulario {
    

    fieldset {
        background-color: v.$gris-claro;
        border-radius: 10px;
        box-shadow: 5px 5px 5px 0px #8f8f8f;
    }
    p {
        font-size: 1rem;
        color: v.$black-color;
        margin: 2rem 0 0 0;
    }

    legend {
        font-size: 2rem;
        color: v.$black-color;
        font-weight: 600;
    }

    label {
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        font-size: 14px;
    }

    input:not([type="submit"]),
    // da propiedades a todos los input menos a submit
    textarea,
    select {
        padding: 1rem;
        display: block;
        width: 90%;
        background-color: v.$white_color;
        border: 1px solid v.$gris-claro;
        border-radius: 1rem;
        margin-bottom: 2rem;


    }

    textarea {
        height: 10rem;
    }

    .forma-contacto {
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    input[type="radio"] {
        margin: 0;
        width: auto;
    }
    
   

}
