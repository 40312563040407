@use 'base/variables' as v;
@use 'base/mixins' as m;


.titulo {

display: grid;
grid-template-columns: repeat(3, 1fr);
margin: 10rem 0 5rem;

    .line-blue {
        width: 70%;
        height: 0px;
        border: solid 1px v.$blue-color3;
        margin: auto;
    }
    
    
   

    p {
        // border: solid 1px v.$blue-color3;
        font-family: v.$fuente_principal;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 2.4px;
        color: v.$blue-color;
        margin: auto;
        padding-left: 1rem;
        border-left: solid 2px v.$blue-color3;

        @include m.tablet { 
            font-size: 30px;
        }

        @include m.desktop { 
            font-size: 40px;
        }



    }
}