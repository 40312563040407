@use '../base/variables' as v;
@use '../base/mixins' as m;

.footer-imagen {
    position: relative;

    #footer-imagen {
        width: 100%;
        height: 25rem;
        filter: brightness(0.1);
    }
}


.footer-informacion-general {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 80%;
    margin: 5rem 10%;
    display: grid;
    grid-template-columns: (2fr 1fr);
    gap: 2rem;


    @include m.tablet { 
        margin: 3rem 10%;
    }
    // border: solid rgb(10, 238, 170) 1px;

}

.footer-informacion {
    display: block;
    padding: auto;

}


.footer-linea-texto {
    display: flex;

    .logopeq {

        margin: auto 0px;
    }

    p {
        color: v.$white-color;
        margin-left: 1rem;
        font-size: 10px;
    }
}

.footer-logo {
    margin: auto;

    #footer-logo{
        width: 8rem;
        height: 7rem;
        margin: auto;
        filter: brightness(1);
       
    }

}


.copyright {
    display: grid;
    grid-template-columns: (2fr 1fr);
    gap: 2rem;
    height: 3rem;
    background-color: v.$gris-claro;
    

    p {
        margin: auto;
        font-size: 10px;
        text-align: center
    }

    .copyright-text {
        margin: auto;
    }


    .petisoft {

        a {
            color: red;

           
        }

    }
}