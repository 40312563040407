

//TIPOGRAFIA
$fuente_principal : 'Poppins', sans-serif;


// colores
$blue-color: #03101e;
$blue-color2: #132646;
$blue-color3: #0080FF;
$white_color: #FFFFFF;
$claro-color: #BEA374;
$green-color: #05F139;
$black-color: #000000;

$gris-blanco: #f9f9f9;
$blanco: #ffffff;
$gris-claro: #e2e2e2;
$red: #f53333;




// Tamaño Media Queries

$telefono: 20rem;
$tablet: 40rem;
$desktop: 70rem;
$desktopXL: 120rem;
