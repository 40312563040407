@use 'base/variables' as v;
@use 'base/mixins' as m;


.boton {

    margin: 0 auto 2rem;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    background-color: v.$white_color;
    
    border-radius: 10px;


    &:hover {
        cursor: pointer;
        background-color: v.$claro-color;
        border-radius: 1rem;
        color: v.$white_color;
        font-weight: bold;
        
        

    }

    @include m.tablet { 
          width: 250px;
    }


}


.btn-green {
    background-color: v.$green-color;
    min-width: 15%;
   

  

    a {
        color: v.$black-color;
        font-family: v.$fuente_principal;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: auto;


       @include m.tablet { 
        font-size: 20px;
       }
    }
}



.btn-enviar {
    background-color: v.$green-color;
    margin-top: 1rem;
    width: 10rem;
}


.btn-admin {
    padding: 0.2rem 1rem;
    margin: 5rem;
    font-size: 12px;
   
}