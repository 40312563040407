@use 'base/variables' as v;
@use 'base/mixins' as m;


html {
    font-size: 62.5%;
    box-sizing: border-box;
    scroll-snap-type: mandatory;

}

sombra *,
*:before,
*:after {
    box-sizing: inherit;
}


body {
    font-family: v.$fuente_principal;
    font-size: 16px;
    width: 100%;
    margin: 0;

    position: absolute;

}


p {
    font-family: v.$fuente_principal;
    color: v.$black-color;
    overflow-y: hidden;
    text-decoration: none;

}


.left {
    margin-left: 0;
}



a {
    text-decoration: none;
    color: v.$white_color;

    font-family: v.$fuente_principal;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;


}

h1,
h2,
h3 {
    margin: 0 0 5rem 0;
}

h1 {
    font-size: 5rem;
    color: v.$claro-color;
    text-transform: uppercase;

}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 3rem;
}

.aling-rigth {

    justify-self: end;

    @include m.tablet {
        justify-self: normal;
    }


}

.aling-left {
    justify-self: start;
}

.icon-wsp {
    position: fixed;
    width: 7rem;
    height: 7rem;
    bottom: 5rem;
    right: 2rem;
    color: white;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    z-index: 3;


    @include m.tablet {
        right: 5%;
    }
}

.espacio {
    height: 4rem;
    margin: 0;
    padding: 0;


    // border: solid 1px v.$blue-color3; 
}
picture {
    height: 0px;
    font-size: 0px;
}