@use '../base/variables' as v;
@use '../base/mixins' as m;



.servicios-imagen {

    // border: solid rgb(10, 238, 29);
    position: relative;

    img {
        width: 100%;
        height: 35rem
    }


}

.servicios-contenedor-tarjetas {
    display: grid;
    width: 80%;
    padding: 5% 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;


    @include m.tablet {
        grid-template-columns: repeat(3, 1fr);
        padding: 25% 0;
    }

    @include m.tablet {
        grid-template-columns: repeat(3, 1fr);
        padding: 5% 0;
    }



    // border: solid rgb(71, 14, 202);

}


.servicio-tarjeta {



    display: grid;
    width: 100%;
    max-width: 20rem;
    background-color: v.$white_color;
    // box-shadow: 5px 5px 5px 0px #8f8f8f;


    @include m.tablet {
        max-width: 40rem;
    }





    border: solid rgb(41, 41, 41) 1px;
    border-radius: 5px;



    .servicio-icon {
        // border: solid rgb(10, 238, 29);
        margin-bottom: 0;

        margin-top: 0.5rem;
        margin-left: 0.5rem;
        text-align: left;

      

        img {
            
            width: 3rem;
            height: 3rem;
        }
    }

    p {
        text-align: left;
        color: v.$black-color;
        font-size: 10px;
        margin: 0.5rem 0 0.5rem 0.5rem;


        @include m.tablet {
            font-size: 16px;
        }

        @include m.desktop {
            font-size: 20px;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: v.$claro-color;
        border-radius: 1rem;
        color: v.$white_color;
        font-weight: normal;


    }


}


// ################## Hoja servicios #################

.servicios-hoja-contenedor {
    background-color: v.$white_color;
    padding-top: 2rem;


    @include m.tablet {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @include m.desktop {
        grid-template-columns: repeat(3, 1fr);
    }



}

.servicio-hoja-tarjeta {
    width: 25rem;
    margin: 5rem auto;
    box-shadow: 5px 5px 5px 0px #8f8f8f;
    border: solid v.$blue-color 1px;

    @include m.tablet {
        width: 25rem;
    }

    @include m.desktop {
        width: 30rem;
    }


    .servicio-hoja-tarjeta-imagen {

        img {
            width: 100%;
            height: 20rem;


            @include m.tablet {
                width: 100%;
                height: 20rem;
            }

            @include m.desktop {
                width: 100%;
                height: 30rem;
            }
        }

    }

    .servicio-hoja-tarjeta-texto {
        .servicio-hoja-titulo {
            p {
                font-size: 14px;
                font-weight: 700;
                margin: 0 0.5rem;

                @include m.tablet {
                    font-size: 18px;
                }

            }


        }

        .servicio-hoja-texto {
            font-size: 10px;
            margin: 0 0.5rem;
            text-align: justify;

            @include m.tablet {
                margin: 0 1rem;
                font-size: 14px;
            }
        }
    }


    
}

.linkexterno{
    color: v.$claro-color;
    font-weight: 900;
}

.linkexternonegro{
    
    font-weight: 900;
}