@use '../base/variables' as v;
@use '../base/mixins' as m;




.perfil {
   

    @include m.tablet { 
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
    }



    .perfil-imagen {
       
        picture {
            
            img {
                margin: 0 25%;
                width: 50%;
                height: 30%;
                


                @include m.tablet { 

                    margin: 0 15%;
                    width: 80%;
                    height: 100%;
                    max-height: 50rem;
                    

                }
            }
        }
    }

    .perfil-text{

        margin-top: 2rem;
        @include m.tablet { 
            margin-top: 0rem;
        }

        p{
            margin: 0rem auto;
            font-size: 14px;
            line-height: 1.5;
            text-align: justify;

            @include m.tablet { 
                font-size: 16px;
            }

            @include m.desktop { 
                font-size: 18px;
            }
        }
    }
               
}