@use 'variables' as v;

@mixin telefono {
    @media (min-width: v.$telefono) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: v.$tablet) {
        @content;
    }
}

@mixin desktop{
    @media (min-width:v.$desktop) {
        @content;
    }
}

@mixin desktopXL{
    @media (min-width:v.$desktopXL) {
        @content;
    }
}

