@use '../base/variables' as v;
@use '../base/mixins' as m;




.inicio-imagen {

    position: relative;
    height: 50rem;

    @include m.tablet { 
        height: 57rem;
    }

    @include m.desktop {
        height: 65rem;
        
    }
    

    picture {

        height: 0;

        #inicio-imagen {
            width: 100%;
            height: 100%;
            opacity: 0.3;

            // border: solid red 1px;

            @include m.tablet {
                opacity: 0.8;
            }

           

        }


    }



}



.contenedor-inicio {
    width: 80%;
    min-width: 34rem;;
    margin: auto;
    display: grid;
    grid-template-rows: repeat(4, auto);
    gap: 2rem;
    padding-top: 5rem;
    z-index: 1;
    position: absolute;
    z-index: 4;
    top: 10%;


    // border: solid rgb(238, 10, 10) 1px;

    @include m.tablet {
        width: 50%;
        top: 5%;
        margin-left: 2rem;

        // border: solid rgb(37, 230, 20) 1px;

    }


    @include m.desktop {
        width: 50%;
        top: 0%;
        margin-left: 2rem;

        // border: solid rgb(29, 203, 247) 1px;

    }



}




#inicio-logo {
    display: none;



    @include m.tablet {
        display: block;

        picture {

            img {
                width: 1rem;
                height: 15rem;

                @include m.desktop {
                    width: 15rem;
                    height: 20rem;
                }
            }
        }

    }
}


.inicio-h1 {
    width: 100%;
    margin-top: 0;
    text-align: center;


    @include m.tablet {
        margin-top: 5%;
        text-align: center;

    }


    h1 {
        font-size: 30px;
        margin: 0;


        @include m.tablet {
            font-size: 40px;
        }

        @include m.desktop {
            font-size: 50px;
        }
    }



    @include m.desktop {
        margin-top: 10%;
        text-align: center;
    }

}

.inicio-h2 {
    margin-top: 5%;
    text-align: center;


    @include m.desktop {
        margin-top: 10%;
    }


    p {
        color: var(--color-white, #FFF);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.8px;

        @include m.desktop {
            font-size: 22px;
            line-height: 28px;
        }
    }

    span {
        color: var(--color-white, #FFF);
        text-align: center;
        -webkit-text-stroke-width: 1;
        -webkit-text-stroke-color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.9px;

        @include m.desktop {
            line-height: 40px;

            font-size: 25px;
        }
    }

}