@use 'base/variables' as v;
@use 'base/mixins' as m;



.fondo-imagen{
    position: fixed;
    top: 20rem;
    left: 2.85rem;
    height: 20rem;
    z-index: -5;

    picture {

        img {

            width: 100%;
            height: 20rem;
        


        }
    }
}