@use '../base/variables' as v;
@use '../base/mixins' as m;


.barra {
    // position: fixed;
    display: grid;
    grid-template-columns: repeat(2, auto);
    width: 100%;
    background-color: v.$blue-color;
   


    @include m.tablet {
        width: 80%;
        justify-content: end;
        margin: 0 auto;


        // border: solid rgb(11, 238, 22) 1px;
    }


    @include m.desktop {


        // border: solid rgb(244, 247, 255) 1px;
    }

}


.logo {

    align-content: initial;
    margin: 1rem auto 0;
    width: 80px;
    height: 70px;

    // border: solid rgb(238, 56, 11) 1px;

    img {
        width: 80px;
        height: 70px;
    }


    @include m.tablet {
        display: none;
    }

}


.navegacion-principal {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    border-radius: 10px;


    @include m.tablet {
        display: block;
        margin: auto;

    }
}



.nav-bar {
    display: block;
    margin: 1rem auto;
    text-align: center;



    @include m.tablet {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, auto);
        gap: 1rem;

        // border: solid 1px v.$blue-color3;
    }

}

.nav-tex {


    font-family: v.$fuente_principal;
    // width: 10rem;
    margin: 0 auto;
    height: 75%;
    display: flex;
    flex-direction: column;
    row-gap: 1.1rem;
    border-radius: 10px;
    background-color: v.$blue-color;
    margin-right: 1rem;
    

    @include m.telefono {
        display: none;
    }

    @include m.tablet {
        display: grid;
        margin-right:0 ;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;
        font-size: 14px;
        
    }

    @include m.desktop {
        gap: 2rem;
        font-size: 16px;
    }




    a {
        color: v.$white_color;
        padding: 0 0.5rem ;
        text-align: center;
        margin: auto;
        



        &:hover {
            cursor: pointer;
            background-color: v.$claro-color;
            border-radius: 1rem;
            color: v.$black-color;
            font-weight: normal;
            

        }

    }

    
}


.mobile-menu {
    cursor: pointer;
  
      @include m.tablet { 
            display: none;
      }
  }

.mostrar {
    display: flex;
}